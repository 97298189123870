/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import DatalayerComponent from './components/datalayer.component'
import NavbarComponent from './components/navbar.component'
import ToolsbarComponent from './components/toolsbar.component'

document.addEventListener('DOMContentLoaded', () => {
	//Component
	new NavbarComponent()
	new ToolsbarComponent()
	new DatalayerComponent()

	//Front page only
	if ($('.home').length > 0) {
		import(
			'@scripts/services/home.service' /* webpackChunkName: "scripts/home.service" */
			).then(({default: HomeService}) => {
			new HomeService()
		})
	}

	//Fullscreen template only
	if ($('.fullscreen').length > 0) {
		import(
			'@scripts/services/layout.service' /* webpackChunkName: "scripts/layout.service" */
			).then(({default: LayoutService}) => {
			new LayoutService()
		})
	}

	//On pages with a SIT block only
	if ($('.has-wpet-block-list').length > 0) {
		import(
			'@scripts/services/wpet.service' /* webpackChunkName: "scripts/wpet.service" */
			).then(({default: WpetService}) => {
			new WpetService()
		})
	}

	if ($('[data-banner-video]').length > 0 && window.matchMedia('(min-width: 1001px)').matches) {
		import(
			'@scripts/components/banner-video.component' /* webpackChunkName: "scripts/banner-video.component" */
			).then(({default: BannerVideoComponent}) => {
			new BannerVideoComponent()
		})
	} else if ($('#header-banner-slider').length > 0) {
		import(
			'@scripts/components/banner.component' /* webpackChunkName: "scripts/banner.component" */
			).then(({default: BannerComponent}) => {
			new BannerComponent()
		})
	}
})
