export default class DatalayerComponent {
    constructor() {
        // https://developers.didomi.io/cmp/web-sdk/reference/api#getuserconsentstatusforpurpose-purposeid
        DatalayerComponent.clic_contact_offer()
        DatalayerComponent.form_submit()
        DatalayerComponent.search()
        DatalayerComponent.share()
        DatalayerComponent.newsletter_susbscription()
        DatalayerComponent.cta_reserver()
        DatalayerComponent.view_minicart()
        DatalayerComponent.select_item()
        DatalayerComponent.view_item_list()
    }

    static clic_contact_offer() {
        if ($('body').hasClass('single')) {
            const wpetFields = IRISCollectionTheme.queriedObject.wpetFields
            const clic_contact_provider_id = wpetFields.identifiant
            const clic_contact_provider_name = wpetFields.nom

            $('.GtmButtonConsultationPhonePrestataire').on('click', () => {
                dataLayer.push({
                    event: 'clic_contact_offer',
                    clic_contact_method: 'Téléphone',
                    clic_contact_provider_id: clic_contact_provider_id,
                    clic_contact_provider_name: clic_contact_provider_name,
                })
            })
            $('.GtmButtonConsultationMailPrestataire').on('click', () => {
                dataLayer.push({
                    event: 'clic_contact_offer',
                    clic_contact_method: 'email',
                    clic_contact_provider_id: clic_contact_provider_id,
                    clic_contact_provider_name: clic_contact_provider_name,
                })
            })
            $('.GtmButtonConsultationSiteWebPrestataire').on('click', () => {
                dataLayer.push({
                    event: 'clic_contact_offer',
                    clic_contact_method: 'Site web',
                    clic_contact_provider_id: clic_contact_provider_id,
                    clic_contact_provider_name: clic_contact_provider_name,
                })
            })
            $('.GtmButtonConsultationFacebookPrestataire').on('click', () => {
                dataLayer.push({
                    event: 'clic_contact_offer',
                    clic_contact_method: 'Facebook',
                    clic_contact_provider_id: clic_contact_provider_id,
                    clic_contact_provider_name: clic_contact_provider_name,
                })
            })
            $('.GtmButtonConsultationTwitterPrestataire').on('click', () => {
                dataLayer.push({
                    event: 'clic_contact_offer',
                    clic_contact_method: 'Twitter',
                    clic_contact_provider_id: clic_contact_provider_id,
                    clic_contact_provider_name: clic_contact_provider_name,
                })
            })
            $('.GtmButtonConsultationTripadvisorPrestataire').on('click', () => {
                dataLayer.push({
                    event: 'clic_contact_offer',
                    clic_contact_method: 'Tripadvisor',
                    clic_contact_provider_id: clic_contact_provider_id,
                    clic_contact_provider_name: clic_contact_provider_name,
                })
            })
        }
    }

    static form_submit() {
        $('.frm_forms > form').on('submit', function () {
            const formName = $(this).attr('id')
            console.log({
                event: 'form_submit',
                form_type: formName,
            })
            dataLayer.push({
                event: 'form_submit',
                form_type: formName,
            })
        })
    }

    static search() {
        let timeoutId = null

        $(document).on('input', '.iris-advanced-search-input__field', function () {
            const val = $(this).val()
            clearTimeout(timeoutId)

            timeoutId = setTimeout(
                function () {
                    if ($(this).val() === val) {
                        dataLayer.push({
                            event: 'search',
                            search_type: 'générique',
                            search_term: val.substring(0, 100),
                        })
                    }
                }.bind(this),
                2000,
            )
        })
    }

    static share() {
        $('.GtmButtonPartageFacebook').on('click', () => {
            dataLayer.push({
                event: 'share',
                share_method: 'facebook',
                share_location: 'bas de la fiche offre',
            })
        })
        $('.GtmButtonPartageTwitter').on('click', () => {
            dataLayer.push({
                event: 'share',
                share_method: 'twitter',
                share_location: 'bas de la fiche offre',
            })
        })
        $('.GtmButtonPartageMail').on('click', () => {
            dataLayer.push({
                event: 'share',
                share_method: 'email',
                share_location: 'bas de la fiche offre',
            })
        })
    }

    static newsletter_susbscription() {
        $('#sib_signup_form_2').on('submit', () => {
            dataLayer.push({
                event: 'newsletter_susbscription',
            })
        })
    }

    static cta_reserver() {
        $('.GtmButtonConsultationDispoResaPrestataire').on('click', () => {
            dataLayer.push({
                event: 'cta_reserver',
                cta_reserver_origin: 'Fiche offre - volet droit',
            })
        })
    }

    static view_minicart() {
        $('#widget-panier').on('click', () => {
            dataLayer.push({
                event: 'view_minicart',
            })
        })
    }

    static select_item() {
        // classic offers list
        if ($('.wpet-block-list__offers').length > 0) {
            $('.iris-card').on('click', function () {
                dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.

                const wpId = $(this).data('id')
                const wpetOfferId = $(this).data('layer-wpet-offer-id')
                const codeResa = $(this).data('layer-wpet-offer-booking')
                const identifiantTag = codeResa ?? wpetOfferId
                const name = $(this).data('layer-wpet-offer-title')
                const serverUrl = IRISCollectionTheme.serverUrl
                const pageName = IRISCollectionTheme.queriedObject.post_title
                const position = $(this).parent('.wpet-block-list__offer').index()
                const location = $(this).find('.wpet-location').text().trim()
                const breadcrumbs = []
                $('#breadcrumbs a').each(function (index) {
                    breadcrumbs.push($(this).text().trim())
                })

                console.log({
                    event: 'select_item',
                    ecommerce: {
                        items: [
                            {
                                item_id: `${wpId}`,
                                item_name: DatalayerComponent.formatString(name),
                                affiliation: `${identifiantTag}`,
                                currency: 'EUR',
                                discount: 0,
                                index: 1,
                                item_list_id: `${position + 1}`,
                                item_list_name: DatalayerComponent.formatString(pageName),
                                location_id: DatalayerComponent.formatString(location),
                            },
                        ],
                    },
                })

                dataLayer.push({
                    event: 'select_item',
                    ecommerce: {
                        items: [
                            {
                                item_id: `${wpId}`,
                                item_name: DatalayerComponent.formatString(name),
                                affiliation: `${identifiantTag}`,
                                currency: 'EUR',
                                discount: 0,
                                index: 1,
                                item_list_id: `${position + 1}`,
                                item_list_name: DatalayerComponent.formatString(pageName),
                                location_id: DatalayerComponent.formatString(location),
                            },
                        ],
                    },
                })
            })
        }

        // Leaflet popup (side map in list and interactive map page)
        $(document).on('click', '.leaflet-popup .iris-card', function () {
            dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.

            const wpId = $(this).data('id')
            const codeResa = $(this).data('layer-wpet-offer-booking')
            const identifiantTag = codeResa ?? wpId
            const name = $(this).data('layer-wpet-offer-title')
            const serverUrl = IRISCollectionTheme.serverUrl
            const pageName = IRISCollectionTheme.queriedObject.post_title

            dataLayer.push({
                event: 'select_item',
                ecommerce: {
                    items: [
                        {
                            item_id: `${wpId}`,
                            item_name: DatalayerComponent.formatString(name),
                            affiliation: `${identifiantTag}`,
                            currency: 'EUR',
                            discount: 0,
                            index: 1,
                            item_list_name: DatalayerComponent.formatString(pageName),
                        },
                    ],
                },
            })
        })

        // Cross selling
        if ($('.iris-carousel--cross-selling').length > 0) {
            $('.iris-carousel--cross-selling .iris-card').on('click', function () {
                dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.

                const wpId = $(this).data('id')
                const codeResa = $(this).data('layer-wpet-offer-booking')
                const identifiantTag = codeResa ?? wpId
                const name = $(this).data('layer-wpet-offer-title')
                const serverUrl = IRISCollectionTheme.serverUrl
                const pageName = IRISCollectionTheme.queriedObject.post_title
                const location = $(this).find('.wpet-location').text().trim()

                dataLayer.push({
                    event: 'select_item',
                    ecommerce: {
                        items: [
                            {
                                item_id: `${wpId}`,
                                item_name: DatalayerComponent.formatString(name),
                                affiliation: `${identifiantTag}`,
                                currency: 'EUR',
                                discount: 0,
                                index: 1,
                                item_list_name: `Cross sell - ${DatalayerComponent.formatString(pageName)}`,
                                location_id: DatalayerComponent.formatString(location),
                            },
                        ],
                    },
                })
            })
        }
    }

    static view_item_list() {
        if ($('.wpet-list__offers').length > 0) {
            $('.wpet-list__offers .iris-card').each(function () {
                dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.

                const wpId = $(this).data('id')
                const codeResa = $(this).data('code-reservation')
                const identifiantTag = codeResa ?? wpId
                const name = $(this).find('.iris-card__content__title').text().trim()
                const serverUrl = IRISCollectionTheme.serverUrl
                const pageName = IRISCollectionTheme.queriedObject.post_title
                const position = $(this).parent('.wrapper_wpet_offer').index()
                const location = $(this).find('.wpet-location').text().trim()
                const breadcrumbs = []
                $('#breadcrumbs a').each(function (index) {
                    breadcrumbs.push($(this).text().trim())
                })

                dataLayer.push({
                    event: 'view_item_list',
                    ecommerce: {
                        items: [
                            {
                                item_id: `${wpId}`,
                                item_name: DatalayerComponent.formatString(name),
                                affiliation: `${identifiantTag}`,
                                currency: 'EUR',
                                discount: 0,
                                index: 1,
                                item_list_id: `${position + 1}`,
                                item_list_name: DatalayerComponent.formatString(pageName),
                                location_id: DatalayerComponent.formatString(location),
                            },
                        ],
                    },
                })
            })
        }
    }

    static formatString(string) {
        // Check is a string
        if (
            (typeof string === 'string' && !string.trim()) ||
            typeof string === 'undefined' ||
            string === null
        ) {
            return null
        }

        // Remove accent
        let cleanString = string.normalize('NFD').replace(/[\u0300-\u036f]/g, '')

        // Transform in lower case
        cleanString = cleanString.toLowerCase()

        return cleanString
    }
}
