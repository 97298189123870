export default class ToolsbarComponent {
	constructor() {
		ToolsbarComponent.langSwitcher()
		'ontouchstart' in window ? ToolsbarComponent.toggleIsActiveClass() : ''
	}

	static langSwitcher() {
		const current_lang = '[data-id="wpiris_current-lang"]'
		const list_lang_item = '[data-id="wpiris-lang-list"] > a'

		//Init select lang
		$(window).on('load', () => {
			setTimeout(() => {
				const current_language = $('html').attr('lang').replace(/-.*/gi, '')
				$(current_lang).text(current_language)

				//Remove current language on list (init)
				$(list_lang_item).each(function () {
					if ($(this).text() === current_language) {
						$(this).hide()
					}
				})
			}, 300)
		})
	}

	static toggleIsActiveClass() {
		$('[data-langs-toggle]').on('click', function () {
			$(this).toggleClass('is-active')
		})
	}
}
